<template>
  <div class="web-container" id="nav">
    <nav class="nav" style="overflow:hidden">
      <!-- overflow: hidden -->
      <h3 style="position: relative; padding-left: 5rem; ">
        <b-img src="logo.jpg" v-if="system === 0" style="
            position: absolute;
            left: 0;
            top: -60%;
            height: 6rem;
            z-index: -1;
          " fluid alt="Fluid image">
        </b-img>
        <b-img src="logo2.png" v-if="system === 1" style="
            position: absolute;
            left: 0;
            top: -60%;
            height: 6rem;
            z-index: -1;
          " fluid alt="Fluid image">
        </b-img>
        <b-img src="logo3.png" v-if="system === 2" style="
            position: absolute;
            left: 0;
            top: -60%;
            height: 6rem;
            z-index: -1;
          " fluid alt="Fluid image">
        </b-img>
        <b-img src="logo4.png" v-if="system === 3" style="
            position: absolute;
            left: 10%;
            top: -50%;
            height: 5.6rem;
            z-index: -1;
          " fluid alt="Fluid image">
        </b-img>
        <b-img src="logo5.png" v-if="system === 4" style="
            position: absolute;
            left: 0%;
            top: -50%;
            height: 5.6rem;
            z-index: -1;
          " fluid alt="Fluid image">
        </b-img>
        <b-img src="logo6.png" v-if="system === 5" style="
            position: absolute;
            left: 5%;
            top: -50%;
            height: 5.6rem;
            z-index: -1;
          " fluid alt="Fluid image">
        </b-img>
        <b-img src="https://hx-1323651380.cos.ap-shanghai.myqcloud.com/logl.jpg" v-if="system === 6" style="
            position: absolute;
            left: 5%;
            top: -50%;
            height: 5.6rem;
            z-index: -1;
          " fluid alt="Fluid image">
        </b-img>
        <b-img src="logo8.jpg" v-if="system === 7" style="
            position: absolute;
            left: 15%;
            top: -44%;
            transform: scale(1.4);
            height: 5.6rem;
            z-index: -1;
          " fluid alt="Fluid image">
        </b-img>
        <span v-if="[7].indexOf(system) === -1" :style="{ marginLeft: system !== 0 ? '3rem' : '0' }">
          {{ appName }}{{ system === 0 ? "企业" : "" }}{{ systemTitle }}</span>
        <div v-else style="width:80px"></div>
      </h3>
      <ul>
        <li class="nav_li" @click="redirctTo('#nav')">首页</li>
        <li class="nav_li" @click="redirctTo('#brie_introduction')">
          产品简介
        </li>
        <li class="nav_li" @click="redirctTo('#submit')">报送年报</li>
        <li class="nav_li" @click="redirctTo('#abnormal-operation')">
          经营异常移出
        </li>
        <li class="nav_li" @click="redirctTo('#application_scenarios')">
          应用场景
        </li>
        <li class="nav_li" @click="redirctTo('#contact_us')">联系我们</li>
        <li class="nav_li" v-if="h5Url" @click="redirctTo('#search_order')">订单查询</li>
      </ul>
      <div class="contact">
        <img src="https://staticcdn.shuidi.cn/nbtstatic/img/phone.07dcf0af.png" alt="" />
        <span style="font-size: 24px; margin-left: 5px">{{
          telephone | phoneFilter(" ")
        }}</span>
      </div>
    </nav>
    <div class="box1">
      <div class="boxDown">
        <span class="title" v-if="system !== 0" style="
            color: #1f81f8;
            z-index: 1;
            position: absolute;
            left: -180px;
            top: 80px;
            font-size: 34px;
            font-weight: 600;
          ">{{ appName }}{{ system === 0 ? "企业" : "" }}{{ systemTitle }}</span>
        <div class="shiyong" v-if="h5Url"><a :href="h5Url" target="blank"> 代理申报</a></div>
        <div class="chaxun" v-if="h5Url"><a :href="h5Url + '/#/pages_D/detail/index'" target="blank"> 订单查询</a></div>
        <!-- <b-button class="button" variant="primary"
          style="background-color: #12c554;border-color: #12c554;padding:7.5px 23px;margin-right: 14px;margin-top: 2px;"
          :href="h5Url + '/#/pages_D/detail/index'">订单查询</b-button> -->
        <span class="title" v-if="system !== 0" style="
            color: #1f81f8;
            z-index: 1;
            position: absolute;
            left: -180px;
            top: 80px;
            font-size: 34px;
            font-weight: 600;
          ">{{ appName }}{{ system === 0 ? "企业" : "" }}{{ systemTitle }}</span>
        <div class="shiyong"><a :href="mpUrl" target="blank"> 小程序申报</a></div>
        <div class="kefu">
          <a :href="'tel:' + telephone" target="blank">在线咨询</a>
        </div>
      </div>
      <div :class="['boxBg', system === 0 ? 'boxBg1' : 'boxBg2']">
        <div class="layer">
          <span class="title" style="color: #1f81f8; z-index: 1">{{ appName }}{{ system === 0 ? "企业" : "" }}{{ systemTitle
          }}</span>
          <span class="text">请打开微信，访问{{ appName }}年报通小程序</span>
          <span class="text">了解年报报送</span>
        </div>
      </div>
    </div>
    <div class="box2">
      <div class="virtua-anchor-point" style="position: absolute; top: -2rem" id="brie_introduction"></div>
      <span class="title">产品简介</span>
      <div class="context">
        <div class="downLeft">
          <div class="row1">
            <!-- <img src="https://staticcdn.shuidi.cn/nbtstatic/img/logo1.5eebcf84.png" alt=""> -->
            <span>{{ appName }}{{ system === 0 ? "企业" : "" }}{{ systemTitle }}</span>
          </div>
          <p class="row2">
            {{ appName
            }}{{
  system === 0 ? "企业" : ""
}}{{ systemTitle
}}是一个服务中小微企业、个体工商户等经营主体，为其提供年报代报、经营异常移出服务的平台，基于【操作简单】和【报送便捷】的服务理念，让经营主体更好的履行年报义务，不因年报未报或迟报而形成失信记录；为被标记经营异常的主体代办移出异常服务，修复经营主体信用，共同营造良好的社会信用环境。
          </p>
        </div>
        <div style="width: 500px; height: 500px">
          <b-img src="
          1.jpg" fluid alt="Fluid image" style="">
          </b-img>
        </div>
      </div>
    </div>
    <div class="box3">
      <div class="virtua-anchor-point" style="position: absolute; top: -4rem" id="submit"></div>
      <p class="title">
        {{ appName }}{{ system === 0 ? "企业" : "" }}{{ systemTitle }}，快捷报送年报
      </p>
      <p class="text">
        经营者可以通过手机随时随地报送年报，操作简单。报送便捷，更有专业客服全程服务，让您省时、省事、省力
      </p>
      <div class="context">
        <div class="bg-image">
          <p style="
              position: absolute;
              color: #fff;
              width: 180px;
              left: 80px;
              top: 250px;
            ">
            微信搜索“{{ appName
            }}{{
  system === 0 ? "企业" : ""
}}年报通”小程序，咨询在线客服，开通年报代报服务。
          </p>
          <span style="position: absolute; color: #fff; right: 134px; top: 306px">{{ telephone | phoneFilter(" ")
          }}</span>
        </div>
      </div>
    </div>
    <div class="box4">
      <div class="virtua-anchor-point" style="position: absolute; top: -5rem" id="abnormal-operation"></div>
      <div class="bg-image"></div>
    </div>
    <div class="box5">
      <div class="virtua-anchor-point" style="position: absolute; top: -5rem" id="application_scenarios"></div>
      <span class="title">应用场景</span>
      <div class="context">
        <div class="bg-image"></div>
        <div class="content">
          <p>
            经营主体（公司/个体户/农专社）应当于每年1月1日至6月30日，通过企业信用信息公示系统向市场监督管理部门报送上一年度年度报告，并向社会公示；当年设立登记的主体，自下一年起报送并公示。未按照规定时间报送年度报告将被列入“经营异常名录”或者标记为“异常状态”。未报送年报的主体可尽快补报年报，如已被列入经营异常的，可通过{{
              systemTitle }}平台申请移出。
          </p>
        </div>
      </div>
    </div>
    <div class="footerBox">
      <div class="box footer">
        <div class="footLeft">
          <ul>
            <li class="nav_li" @click="redirctTo('#nav')">首页</li>
            <li class="nav_li" @click="redirctTo('#brie_introduction')">产品简介</li>
            <li class="nav_li" @click="redirctTo('#submit')">报送年报</li>
            <li class="nav_li" @click="redirctTo('#abnormal-operation')">经营异常移出</li>
            <li class="nav_li" @click="redirctTo('#application_scenarios')">应用场景</li>
            <li class="nav_li" @click="redirctTo('#contact_us')" target="blank">联系我们</li>
          </ul>
          <div class="company">
            {{ appName
            }}{{
  system === 0 ? "企业" : ""
}}{{ systemTitle }}由官方备案征信机构-国家中小企业公共服务示范平台{{
  company
}}有限公司运营 &nbsp; &nbsp; 联系电话：{{
  telephone | phoneFilter(" ")
}}
            <br />Copyright© 2019-2022 {{ appName
            }}{{ system === 0 ? "企业" : "" }}{{ systemTitle }}. All Rights Reserved.
            <!-- {{icp}} -->
            <a href="https://beian.miit.gov.cn" style="" target="_blank" previewlistener="true"> {{ icp }}</a>
          </div>
        </div>
        <!-- <img src="https://staticcdn.shuidi.cn/nbtstatic/img/scan-hide.1dc353c2.png" style="width:115px ;" alt=""> -->
      </div>
    </div>
  </div>
</template>

<script>
import appInfo, { system, systemTitle } from '../utils/config.js';
export default {
  // transform: scale(1.25,1.25) translateY(316px)
  data () {
    return {
      system,
      appInfo,
      systemTitle
    };
  },
  computed: {
    appName () {
      console.log("system", this.system);
      return this.appInfo[this.system].appName;
    },
    telephone () {
      return this.appInfo[this.system].telephone;
    },
    h5Url () {
      return this.appInfo[this.system].h5Url;
    },
    mpUrl () {
      return this.appInfo[this.system].mpUrl;
    },
    company () {
      return this.appInfo[this.system].company;
    },
    icp () {
      return this.appInfo[this.system].icp;
    },
  },
  created () { },
  filters: {
    phoneFilter (val, chat) {
      let temp = val;
      return (
        temp.slice(0, 3) + chat + temp.slice(3, 7) + chat + temp.slice(7, 11)
      );
    },
  },
  methods: {
    redirctTo (elName) {
      if (elName == '#search_order') {
        window.location.href = this.h5Url + '/#/pages_D/detail/index'
        return
      }
      var target = document.querySelector(elName);
      console.log(target);
      target.scrollIntoView({ behavior: "smooth", block: "start" });
    },
  },
};
</script>

<style lang="less" scoped>
.web-container {
  width: 1520px; // 1520px
  margin: auto;
  overflow: hidden;

  nav {
    display: flex;
    justify-content: center;
    position: fixed;
    width: 1520px;
    height: 70px;
    padding: 14px;
    background-color: rgb(255, 255, 255);
    z-index: 1;

    img {
      height: 100%;
      margin: 0 4px;
    }

    ul {
      display: flex;
      list-style: none;
      align-items: center;
      height: 100%;

      li {
        user-select: none;
        cursor: pointer;
        padding: 20px;
        vertical-align: baseline;
        // font-family: 微软雅黑, Microsoft YaHei, Arial, sans-serif;
      }

      .nav_li:hover {
        color: #0056b3;
        background-color: "#ef81f8";
        text-decoration: underline;
      }
    }

    .contact {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      height: 49px;
      line-height: 49px;
      font-size: 16px;
      font-weight: 700;
      color: #1f81f8;
      margin-left: 40px;

      img {
        margin-right: 8px;
        width: 20px;
        height: 20px;
      }
    }
  }

  .box1 {
    position: relative;
    display: flex;
    justify-content: center;
    z-index: 0;
    padding-top: 70px;
    height: 520px;
    overflow: hidden;
    background-image: -webkit-gradient(linear,
        left bottom,
        left top,
        from(#e0eeff),
        to(#8ec1ff)),
      -webkit-gradient(linear, left top, left bottom, from(#1e81f8), to(#1e81f8));
    background-image: linear-gradient(0deg, #e0eeff, #8ec1ff),
      linear-gradient(#1e81f8, #1e81f8);

    .boxBg {
      position: relative;
      width: 1320px;
      left: -50px;
      height: 801px;

      .layer {
        position: absolute;
        width: 200px;
        text-align: center;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        z-index: 1;
        top: 170px;
        left: 570px;
        transform: rotate3d(260, 140, 60, 24deg) skew(-16deg);
        user-select: none;

        .title {
          font-size: 20px;
          margin-bottom: 10px;
        }

        .text {
          font-size: 10px;
        }

        .text:nth-child(2) {}

        .text:nth-child(3) {}
      }
    }

    .boxBg1 {
      background: url("../../public/banner.9556eee1 01.png") 50% no-repeat;
      background-size: contain;
      background-position: 0 20px;
    }

    .boxBg2 {
      // background: url('../../public/banner.9556eee1 02.png') 50% no-repeat;
      background: url("../../public/banner.9556eee1 02.png") 50% no-repeat;
      background-size: contain;
      background-position: 0 20px;
    }

    .boxDown {
      position: absolute;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      padding-left: 40px; //100px;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      padding-top: 206px;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      width: 40.3%;
      right: 0;
      height: 450px;
      z-index: 2;
      user-select: none;

      .shiyong {
        cursor: pointer;
        border-radius: 4px;
        width: 120px;
        text-align: center;
        margin-right: 15px;
        height: 40px;
        font-size: 16px;
        line-height: 40px;
        letter-spacing: 0;
        color: #fff;
        background-color: #1f81f8;
        -webkit-box-shadow: 0 2px 4px 0 rgba(51, 51, 51, 0.2);
        box-shadow: 0 2px 4px 0 rgba(51, 51, 51, 0.2);

        a {
          text-decoration: none;
          color: #fff;
        }

        a:hover {
          color: #0056b3;
          text-decoration: underline;
        }
      }

      .chaxun {
        cursor: pointer;
        border-radius: 4px;
        width: 120px;
        text-align: center;
        margin-right: 15px;
        height: 40px;
        font-size: 16px;
        line-height: 40px;
        letter-spacing: 0;
        color: #fff;
        background-color: #12c554;
        -webkit-box-shadow: 0 2px 4px 0 rgba(51, 51, 51, 0.2);
        box-shadow: 0 2px 4px 0 rgba(51, 51, 51, 0.2);

        a {
          text-decoration: none;
          color: #fff;
        }

        a:hover {
          color: #0056b3;
          text-decoration: underline;
        }
      }

      .button :hover {
        color: #0056b3;
        text-decoration: underline;
      }

      .kefu {
        cursor: pointer;
        border-radius: 4px;
        text-align: center;
        right: 592px;
        width: 120px;
        line-height: 40px;
        height: 40px;
        font-size: 16px;
        color: #1f81f8;
        background-color: #fff;
        -webkit-box-shadow: 0 2px 4px 0 rgba(51, 51, 51, 0.2);
        box-shadow: 0 2px 4px 0 rgba(51, 51, 51, 0.2);
      }
    }
  }
}

.title {
  width: 100%;
  text-align: center;
  font-size: 40px;
  margin-bottom: 94px;
  color: #333;
}

.box2 {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  // min-width: 1200px;
  padding-top: 73px;
  position: relative;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  height: 567px;
  background-color: #f1f6fe;
  overflow: hidden;

  .context {
    width: 1284px;
    display: flex;
    padding: 0 40px;

    .downLeft {
      font-size: 30px;
      color: #333;
      flex: 1;
      -webkit-box-flex: 1;

      .row2 {
        margin-top: 31px;
        font-size: 16px;
        line-height: 30px;
        padding-right: 98px;
        color: #333;
      }
    }
  }
}

.box3 {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .title {
    margin-bottom: 14px;
  }

  .text {
    color: #666;
  }

  .context {
    user-select: none;

    .bg-image {
      position: relative;
      width: 1284px;
      height: 600px;
      background: url("../../public/process.2f3f1ab6.png") no-repeat;
      background-size: 90% 90%;
      background-position: 40px -80px;
    }
  }
}

.box4 {
  position: relative;
  display: flex;
  justify-content: center;

  .bg-image {
    width: 1284px;
    height: 600px;
    background: url("../../public/yichang.038484a1.png") no-repeat;
    background-size: 90% 90%;
    background-position: 40px 0px;
  }
}

.box5 {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  rgb(16, 25, 38) .title {
    margin-bottom: 0px;
  }

  .context {
    position: relative;
    display: flex;
    justify-content: center;
    background-color: #f1f6fe;
    width: 100%;

    .bg-image {
      width: 1200px;
      height: 332px;
      background: url("../../public/box5.eb820cd5.png") no-repeat;
      background-size: 90% 100%;
    }

    .content {
      position: relative;
      width: 1284px;

      p {
        position: absolute;
        font-weight: 400;
        font-stretch: normal;
        letter-spacing: 0;
        color: #333;

        right: 0;
        top: -40px;

        width: 516px;
        background-color: #fff;
        border-radius: 12px;
        border: 1px solid #ddd;
        padding: 62px 40px;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        font-size: 16px;
        line-height: 40px;
      }
    }
  }
}

.footerBox {
  display: flex;
  justify-content: center;
  min-width: 1200px;
  background-color: #fff;
  padding-top: 30px;

  .footer {
    width: 1200px;
    height: 250px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding-right: 90px;

    .footLeft {
      font-size: 16px;

      color: #333;

      ul {
        list-style: none;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;

        li {
          user-select: none;
          cursor: pointer;
          margin-right: 58px;
          text-decoration: none;
          color: #333;
        }

        .nav_li:hover {
          color: #0056b3;
          background-color: "#ef81f8";
          text-decoration: underline;
        }
      }
    }
  }
}

.my-enter,
.my-leave-to {
  // opacity: 0;
  transform: translateX(350px);
}

.my-enter-active,
.my-leave-active {
  transition: all 0.5s ease-in;
  // transform: translateX(0px);
}
</style>
